import "core-js/modules/esnext.iterator.map.js";
import ModalReviewView from "@/components/modals/ModalReviewManagementReviewView.vue";
import ModalReviewViewConfirm from "@/components/modals/ModalReviewManagementReviewViewConfirm.vue";
import ModalReviewViewComplete from "@/components/modals/ModalReviewManagementReviewViewComplete.vue";
import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable,
    ModalReviewView,
    ModalReviewViewConfirm,
    ModalReviewViewComplete
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prev: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    async tableLoader({
      query
    }) {
      let result = await this.$store.dispatch("reviewManagement/getReviewsActive", {
        ...query,
        ...this.reportSettings
      });
      result = {
        items: [...result.data.map(item => {
          var _item$answer, _item$answer2, _item$answer3, _item$answer4, _item$answer5, _item$answer6, _item$answer7, _item$photos$;
          return {
            ...item,
            sku: item.product.sku,
            productName: item.product.name,
            productImage: `https://salesfinder.ru/api/getProductImage?mp=wb&sku=${item.product.sku}`,
            answer: (_item$answer = item.answer) === null || _item$answer === void 0 ? void 0 : _item$answer.text,
            answer_id: (_item$answer2 = item.answer) === null || _item$answer2 === void 0 ? void 0 : _item$answer2.id,
            can_be_approved: (_item$answer3 = item.answer) === null || _item$answer3 === void 0 ? void 0 : _item$answer3.can_be_approved,
            script: (_item$answer4 = item.answer) === null || _item$answer4 === void 0 ? void 0 : _item$answer4.script,
            check: (_item$answer5 = item.answer) === null || _item$answer5 === void 0 ? void 0 : _item$answer5.approve_status,
            status: (_item$answer6 = item.answer) === null || _item$answer6 === void 0 ? void 0 : _item$answer6.send_status,
            answerDate: (_item$answer7 = item.answer) === null || _item$answer7 === void 0 ? void 0 : _item$answer7.created_at,
            image: (_item$photos$ = item.photos[0]) === null || _item$photos$ === void 0 ? void 0 : _item$photos$.mini_url
          };
        })],
        total: result.meta.total,
        success: result.success,
        message: result.message
      };
      return result;
    },
    onRowClick(item) {
      const refresh = this.refresh;
      const refs = this.$refs;
      refs.modalReviewView.open({
        item,
        onComplete(review) {
          refs.modalReviewViewConfirm.open({
            onComplete() {
              refs.modalReviewView.close();
              refs.modalReviewViewComplete.open({
                type: "single"
              });
              refresh();
            },
            type: "single",
            review
          });
        }
      });
    },
    async multipleApprove(data) {
      const refs = this.$refs;
      refs.modalReviewViewConfirm.open({
        async onComplete() {
          refs.modalReviewView.close();
          refs.modalReviewViewComplete.open({
            type: "multiple"
          });
          await this.$store.dispatch("reviewManagement/multipleApprove", data.items.map(item => item.answer_id));
          data.clean();
          data.refresh();
        },
        type: "multiple"
      });
    }
  },
  computed: {
    columns() {
      return [{
        title: "SKU",
        show: true,
        name: "sku",
        width: 100
      }, {
        title: "Товар",
        show: true,
        name: "productName",
        type: "text",
        width: 300,
        getImage: item => item.productImage
      }, {
        title: "Дата отзыва",
        show: true,
        name: "created_date",
        type: "datetimeLocal",
        width: 140
      }, {
        title: "Оценка",
        show: true,
        name: "product_valuation",
        width: 100
      }, {
        title: "Фото отзыва",
        show: true,
        name: "photo",
        width: 140,
        type: "text",
        filter: "text",
        sort: false,
        getImage: item => item.image
      }, {
        title: "Текст отзыва",
        show: true,
        name: "text",
        width: 350,
        sort: false
      }, {
        title: "Текст ответа",
        show: true,
        name: "answer",
        type: "slot",
        width: 350,
        sort: false
      }, {
        title: "Сценарий",
        show: true,
        name: "script",
        width: 160
      }, {
        title: "Проверка",
        show: true,
        name: "check",
        type: "slot",
        width: 160
      }, {
        title: "Статус",
        show: true,
        name: "status",
        type: "slot",
        width: 160
      }, {
        title: "Дата ответа",
        show: true,
        name: "answerDate",
        type: "datetimeLocal",
        width: 140
      }, {
        title: "Магазин",
        show: true,
        name: "token",
        width: 160
      }];
    }
  }
};