var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Подтверждение отправки",
      "width": 400,
      "actions": _vm.actions
    }
  }, [_vm.type === 'single' ? _c('span', [_vm._v(" Пожалуйста, перед отправкой проверьте финальный вариант ответа! ")]) : _c('span', [_vm._v(" Пожалуйста, перед массовой отправкой проверьте созданные ответы. "), _c('br'), _c('br'), _vm._v(" Опубликовать все выбранные ответы? ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };