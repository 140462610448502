var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "reviews-new",
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "id",
      "selectAction": 'multiple-approve',
      "selectable": _vm.selectable,
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable,
      "rowClickable": true
    },
    on: {
      "selectAction": _vm.multipleApprove,
      "rowClick": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "answer",
      fn: function ({
        item
      }) {
        return [item.answer ? _c('span', [_vm._v(_vm._s(item.answer))]) : _c('span', [_vm._v(_vm._s(item.wb_answer))])];
      }
    }, {
      key: "check",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "status-wrap"
        }, [item.check === 10 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "dot grey"
        }), _c('span', {
          staticClass: "status-wrap__item__text grey"
        }, [_vm._v("Авто")])]) : _vm._e(), item.check === 20 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "status-wrap__item__text"
        }, [_vm._v(" Ожидает проверки ")])]) : _vm._e(), item.check === 30 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "dot green"
        }), _c('span', {
          staticClass: "status-wrap__item__text green"
        }, [_vm._v(" Проверен ")])]) : _vm._e()])];
      }
    }, {
      key: "status",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "status-wrap"
        }, [item.status === 10 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "dot green"
        }), _c('span', {
          staticClass: "status-wrap__item__text green"
        }, [_vm._v(" Отправлен ")])]) : _vm._e(), item.status === 20 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "dot grey"
        }), _c('span', {
          staticClass: "status-wrap__item__text grey"
        }, [_vm._v(" Не отправлен ")])]) : _vm._e(), item.status === 30 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "status-wrap__item__text"
        }, [_vm._v(" В очереди на отправку ")])]) : _vm._e()])];
      }
    }])
  }, 'data-table', _vm.$attrs, false)), _c('modal-review-view', {
    ref: "modalReviewView"
  }), _c('modal-review-view-confirm', {
    ref: "modalReviewViewConfirm"
  }), _c('modal-review-view-complete', {
    ref: "modalReviewViewComplete"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };