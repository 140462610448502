var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": _vm.editable ? 'Модерация отзыва' : 'Просмотр отзыва',
      "width": 600,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" SKU: "), _c('b', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.review.sku))])]), _c('div', {
    staticClass: "modal-item__label"
  }, [_c('b', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.review.productName))])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.imageExists,
      expression: "imageExists"
    }],
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.review.productImage,
      "alt": "Product photo",
      "height": "100"
    },
    on: {
      "error": function ($event) {
        _vm.imageExists = false;
      },
      "load": function ($event) {
        _vm.imageExists = true;
      }
    }
  })])]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Текст отзыва")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.review.text,
      expression: "review.text"
    }],
    attrs: {
      "placeholder": "Текст отзыва",
      "disabled": ""
    },
    domProps: {
      "value": _vm.review.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.review, "text", $event.target.value);
      }
    }
  })]), _vm.review.image ? _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Фото отзыва")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "src": _vm.review.image,
      "alt": "Review photo",
      "height": "100"
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Текст созданного ответа")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.review.answer,
      expression: "review.answer"
    }],
    attrs: {
      "placeholder": "Текст созданного ответа",
      "disabled": !_vm.editable
    },
    domProps: {
      "value": _vm.review.answer
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.review, "answer", $event.target.value);
      }
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };