import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  data() {
    const initial_review = {};
    return {
      initial_review,
      review: {
        ...initial_review
      },
      loading: false,
      onComplete: null,
      imageExists: true
    };
  },
  mounted() {
    this.imageExists = true;
  },
  methods: {
    onOpen({
      item,
      onComplete
    }) {
      this.review = {
        ...item
      };
      this.onComplete = onComplete;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        this.loading = false;
        if (!this.editable) {
          close();
        } else {
          this.onComplete && this.onComplete(this.review);
          this.$emit("complete", this.review);
        }
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    editable() {
      return this.review.can_be_approved;
    },
    valid() {
      var _this$review$answer;
      return (_this$review$answer = this.review.answer) === null || _this$review$answer === void 0 ? void 0 : _this$review$answer.length;
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: this.editable ? "Сохранить и отправить отзыв" : "Ок",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid && this.editable,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};